import { Common } from "./Common";
import { Plug } from "./Plug";

export interface ICustomer {
  id: number, 
  is_company: boolean, 
  first_name: string, 
  last_name: string, 
  company_name: string, 
  email: string, 
  phone: string, 
  country: string, 
  city: string, 
  zip: string, 
  address: string, 
  ship_name: string, 
  plug_elec: number, 
}

export class Customer extends Common implements ICustomer {
  id: number = 0;
  is_company: boolean = false;
  first_name: string = "";
  last_name: string = "";
  company_name: string = "";
  email: string = "";
  phone: string = "";
  country: string = "";
  city: string = "";
  zip: string = "";
  address: string = "";
  ship_name: string = "";
  plug_elec: number = 0;

  getElecTypeKind(): number {
    return this.plug_elec;
  }

  getWaterTypeKind(): number {
    return 1;
  }


  getElecLabel(): string | undefined {
    return Plug.prototype.getElecLabel(this.plug_elec);
  }
}
