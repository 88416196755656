import { Common } from "./Common";

export interface IUser {
  id: number;
  username: string;
  email: string;
  is_superuser: boolean;
  is_staff: boolean;
  is_mobile: boolean;
  customer_id: number;
}

export class User extends Common implements IUser {
  id: number = 0;
  username: string = "";
  email: string = "";
  is_superuser: boolean = false;
  is_staff: boolean = false;
  is_mobile: boolean = false;
  customer_id: number = 0;
}
