
import moment from 'moment';
import { Component, Vue } from "vue-property-decorator";

import messageStore, { NotificationType, TResolveFunction } from "@/stores/messageStore";

import { formatFloat } from "@/utilities/utilities";
import { TranslateResult } from "vue-i18n";

@Component
export default class Mixin extends Vue {
  successNotify(msg: string | TranslateResult): void {
    messageStore.state.notification = {show: true, msg, type: NotificationType.SUCCESS}
  }

  errorNotify(msg: string | TranslateResult, e: any, cause?: string): void {
    console.log(e)
    messageStore.state.notification =  {show: true, msg, type: NotificationType.ERROR, cause}
  }

  warningNotify(msg: string | TranslateResult, cause?: string): void {
    messageStore.state.notification =  {show: true, msg, type: NotificationType.WARNING, cause}
  }

  showConfirmDialog(title: string, content: string, warning: string | null, yes: string, no: string): Promise<boolean>{
    return new Promise((resolve: TResolveFunction) => {
      messageStore.state.confirmDialog = {
        show: true, 
        title, 
        content, 
        warning, 
        yes, 
        no, 
        confirmYesAction: resolve,
        // confirmNoAction: reject
      }
    })
  }

  nextTick(cb: () => void): void {
    Vue.nextTick(cb)
  }

  formatDateTime(datetimeMs: number): string {
    return moment(datetimeMs).format('DD/MM/YY HH:mm:ss')
  }

  formatDate(datetimeMs: number): string {
    return moment(datetimeMs).format('DD/MM/YYYY')
  }

  formatDateShort(datetimeMs: number): string {
    return moment(datetimeMs).format('DD/MM/YY')
  }

  parseDate(text: string): number {
    return moment(text, 'DD/MM/YYYY').unix() * 1000
  }

  formatRatio(value: number): string {
    return new Intl.NumberFormat("fr", { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value)
  }

  formatString(length: number, separator: string, s?: string): string {
    let result: string = ""

    if (s) {
      let i: number  = 0
      for (const c of s) {
        if (i != 0 && i % length == 0) {
          result += separator
        }
        result += c
        
        i++
      }

    }

    return result
  }

  formatFloat(value: number, nbDecimal: number, unit: string | undefined =""): string {
    return formatFloat(value, nbDecimal, unit)
  }
}
