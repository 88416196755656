import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fr from "vuetify/src/locale/fr";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

// primary: "#176383"

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // primary: '#176383',
        // secondary: '#b0bec5',
        // accent: '#8c9eff',
        // error: '#b71c1c',
      },
    },
  },
  lang: {
    locales: { fr },
    current: "fr",
  },
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
});
