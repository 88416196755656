export function formatFloat(
  value: number,
  nbDecimal: number,
  unit: string | undefined = ""
): string {
  if (isNaN(value)) {
    return "?" + (unit ? " " + unit : "");
  }

  return (
    value.toLocaleString(undefined, {
      minimumFractionDigits: nbDecimal,
      maximumFractionDigits: nbDecimal,
    }) + (unit ? " " + unit : "")
  );

  // return value.toFixed(nbDecimal) + (unit ? " " + unit : "")
}
