import { Common, SelectValue, TYPE_ELEC, TYPE_WATER } from "./Common";
import { ITransaction, Transaction } from "./Transaction";

const STATUS_AVAILABLE: string = "AVAILABLE";
// const STATUS_DELIVERING: string = "DELIVERING";
// const STATUS_DISABLED: string  = "DISABLED";
import { i18n } from "@/plugins/i18n";


export const TYPES_ELEC: SelectValue[] = [
  { id: 0, label: i18n.t("plugs.elec_aucun") as string},
  { id: 1, label: i18n.t("plugs.elec_mono_16a") as string },
  { id: 2, label: i18n.t("plugs.elec_mono_32a") as string },
  { id: 3, label: i18n.t("plugs.elec_mono_63a") as string },
  { id: 4, label: i18n.t("plugs.elec_mono_125a") as string },
  { id: 5, label: i18n.t("plugs.elec_tetra_32a") as string },
  { id: 6, label: i18n.t("plugs.elec_tetra_63a") as string },
  { id: 7, label: i18n.t("plugs.elec_tetra_125a") as string },
  { id: 8, label: i18n.t("plugs.elec_tetra_250a") as string },
  { id: 9, label: i18n.t("plugs.elec_tetra_400a") as string },
];

const ONE_PHASE = [1, 2, 3, 4];
const THREE_PHASES = [5, 6, 7, 8, 9];

const TYPES_WATER: SelectValue[] = [
  { id: 0, label:  i18n.t("plugs.water_aucun") as string },
  { id: 1, label:  i18n.t("plugs.water_robinet") as string },
];

export interface IPlug {
  id: number;
  pos: number;
  label: string;
  status: string;
  type: string;
  kind: number;

  current_qty: number;
  remaining_qty: number;
  transaction: ITransaction | null;
}

export class Plug extends Common implements IPlug {
  id: number = 0;
  pos: number = 0;
  label: string = "";
  status: string = "";
  type: string = "";
  kind: number = 0;
  mode: number = 0;
  current_qty: number = 0;
  remaining_qty: number = 0;
  transaction: Transaction | null = null;

  getTypeLabel(): string | undefined {
    if (this.type == TYPE_ELEC) {
      return "Electricité";
    }
    if (this.type == TYPE_WATER) {
      return "Eau";
    }

    return "";
  }

  isElec(): boolean {
    return this.type == TYPE_ELEC;
  }

  isWater(): boolean {
    return this.type == TYPE_WATER;
  }

  getKindLabel(): string | undefined {
    if (this.isElec()) {
      return this.getElecLabel(this.kind);
    }
    if (this.isWater()) {
      return this.getWaterLabel(this.kind);
    }

    return "";
  }

  getElecLabel(typeElec: number): string | undefined {
    return this.getLabel(typeElec, TYPES_ELEC);
  }

  getWaterLabel(typeWater: number): string | undefined {
    return this.getLabel(typeWater, TYPES_WATER);
  }

  isAvailable(): boolean {
    return this.status == STATUS_AVAILABLE;
  }

  isFree(kind?: number | undefined): boolean {
    return this.isAvailable() &&
    this.mode == 0 &&
    (kind === undefined || this.kind == kind);
  }

  getNbPhases(): number {
    if (! this.isElec()) {
      return 0;
    }

    if (ONE_PHASE.includes(this.kind)) {
      return 1;
    }
    if (THREE_PHASES.includes(this.kind)) {
      return 3;
    }

    return 0;
  }
}
