import {formatFloat} from "@/utilities/utilities"

export interface SelectValue {
  id: number | string;
  label: string;
}

export const TYPE_ELEC: string = "ELEC";
export const TYPE_WATER: string = "WATER";

const NB_DECIMAL_ELEC: number = 0;
const UNIT_ELEC: string = "kwh";

const NB_DECIMAL_WATER: number = 0;
const UNIT_WATER: string = "L";

export const TIMEOUT_ERROR = 1000 * 60 * 2;
export const TIMEOUT_WARNING = 1000 * 60 * 1;

export class Common {
  formatElec(qty: number): string {
    return formatFloat(qty, NB_DECIMAL_ELEC, UNIT_ELEC);
  }

  formatWater(qty: number): string {
    return formatFloat(qty, NB_DECIMAL_WATER, UNIT_WATER);
  }
  
  isOlderThan(lastUpdateMs: number, date: string | null, ms: number): boolean | null {
    if (date === null) {
      return null;
    }

    return lastUpdateMs - new Date(date).getTime() >= ms;
  }

  getLabel(
    id: number | string | undefined,
    labels: SelectValue[]
  ): string | undefined {
    if (id === undefined) {
      return undefined;
    }
    for (const label of labels) {
      if (label.id == id) {
        return label.label;
      }
    }

    return "?";
  }
}
