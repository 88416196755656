import { TranslateResult } from "vue-i18n";

export type TResolveFunction = (ok: boolean) => void;
// export type TRejectFunction = (e: Error) => void;

export interface MessageStateModel {
  notification: NotificationModel;
  confirmDialog: ConfirmDialogModel;
}

export enum NotificationType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

export interface NotificationModel {
  show: boolean;
  msg: string | TranslateResult;
  type: NotificationType;
  cause?: string;
}

export interface ConfirmDialogModel {
  show: boolean;
  title: string | TranslateResult;
  content: string | TranslateResult;
  warning: string | TranslateResult | null;
  yes: string | TranslateResult;
  no: string | TranslateResult;
  confirmYesAction: TResolveFunction | null;
  // confirmNoAction: TRejectFunction | null;
}

class MessageModule {
  state: MessageStateModel = {
    notification: {
      show: false,
      msg: "",
      type: NotificationType.INFO,
    },
    confirmDialog: {
      show: false,
      title: "",
      content: "",
      warning: null,
      yes: "Oui",
      no: "Non",
      confirmYesAction: null,
      // confirmNoAction: null,
    },
  };
}

export default new MessageModule();
