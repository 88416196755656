import axios from "axios";
import { routeToLogin } from "@/router/router";

//import AxiosError from 'axios-error'

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

//const BASE_URL = process.env.VUE_APP_BASE_URL
const BASE_URL = "/";

// **************** JWT ****************
import jwtDecode from "jwt-decode";

const URL_REQUEST_RESET_PASSWORD = "api/mobile/auth/request_reset_password/";
const URL_RESET_PASSWORD = "api/mobile/auth/reset_password/";
const URL_OBTAIN_JWT = "api/mobile/auth/obtain_token/";
const URL_REFRESH_JWT = "api/mobile/auth/refresh_token/";
const URL_VERIFY_JWT = "api/mobile/auth/verify-token/";

const JWT_ACCESS_LOCAL_KEY = "JWT_ACCESS";
const JWT_REFRESH_LOCAL_KEY = "JWT_REFRESH";
const USERNAME_LOCAL_KEY = "username";

// export interface UrlParams<T> {
//   [Key: string]: T
// }

interface Token {
  token_type: string;
  exp: number;
  jti: string;
  user_id: number;
}

interface RestState {
  jwtAccess: string | null;
  jwtRefresh: string | null;
  username: string | null;
}

class Rest {
  clearUsername(): void {
    localStorage.removeItem(USERNAME_LOCAL_KEY);
    this.jwtState.username = null;
  }

  setUsername(username: string): void {
    this.jwtState.username = username;
    localStorage.setItem(USERNAME_LOCAL_KEY, this.jwtState.username);
  }

  getUsername(): string | null {
    if (!this.jwtState.username) {
      this.jwtState.username = localStorage.getItem(USERNAME_LOCAL_KEY);
    }
    return this.jwtState.username;
  }

  clearJWTRefresh(): void {
    localStorage.removeItem(JWT_REFRESH_LOCAL_KEY);
    this.jwtState.jwtRefresh = null;
  }

  setJWTRefresh(refresh: string): void {
    this.jwtState.jwtRefresh = refresh;
    localStorage.setItem(JWT_REFRESH_LOCAL_KEY, this.jwtState.jwtRefresh);
  }

  getJWTRefresh(): string | null {
    if (!this.jwtState.jwtRefresh) {
      this.jwtState.jwtRefresh = localStorage.getItem(JWT_REFRESH_LOCAL_KEY);
    }
    return this.jwtState.jwtRefresh;
  }

  clearJWTAccess(): void {
    localStorage.removeItem(JWT_ACCESS_LOCAL_KEY);
    this.jwtState.jwtAccess = null;
  }

  setJWTAccess(access: string): void {
    this.jwtState.jwtAccess = access;
    localStorage.setItem(JWT_ACCESS_LOCAL_KEY, this.jwtState.jwtAccess);
  }

  getJWTAccess(): string | null {
    if (!this.jwtState.jwtAccess) {
      this.jwtState.jwtAccess = localStorage.getItem(JWT_ACCESS_LOCAL_KEY);
    }
    return this.jwtState.jwtAccess;
  }

  jwtState: RestState = {
    jwtAccess: null,
    jwtRefresh: null,
    username: null,
  };

  // constructor() {
  // }

  // downloadBlob (response: Dictionary<string>) {
  //   const blob = new Blob([response.data], {type: "application/octet-stream"})
  //   let fileName = response.headers["content-disposition"].split('filename=')[1].split(';')[0]
  //   const search = /^"|"$/gi
  //   fileName = fileName.replaceAll(search, "")

  //   const link = document.createElement('a')
  //   link.href = window.URL.createObjectURL(blob)
  //   link.download = fileName

  //   document.body.appendChild(link);
  //   link.click()
  //   document.body.removeChild(link);
  // }

  postFile(url: string, data: FormData) {
    const headers = {
      headers: this.fillAuthHeaders({}),
    };

    // if (additionalHeaders) {
    //   headers = additionalHeaders
    // }
    //console.log(headers)

    return axios.post(BASE_URL + url, data, headers);
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
  fillAuthHeaders(headers: any): any {
    const access = this.getJWTAccess();
    headers.Authorization = "Bearer " + access;
    // if (access) {
    //   console.log("Bearer", this.decodeToken(access))
    // }
    return headers;
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
  get(url: string, params: any = null): Promise<any> {
    const data = {
      headers: this.fillAuthHeaders({}),
      params,
    };

    //console.log("=>", data)

    // console.log("get", BASE_URL, url)

    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL + url, data)
        .then((result) => {
          if (result.status < 200 || result.status >= 300) {
            reject(new Error(`${result.status} error`));
            return;
          }

          resolve(result);
          return;
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  disconnect() {
    this.clearJWTAccess();
    this.clearJWTRefresh();
    routeToLogin();
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
  delete(url: string, additionalHeaders: any = null) {
    const headers = {
      headers: this.fillAuthHeaders(additionalHeaders ? additionalHeaders : {}),
    };

    // if (additionalHeaders) {
    //   headers = additionalHeaders
    // }
    //console.log(headers)

    return axios.delete(BASE_URL + url, headers);
  }

  post(
    url: string,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
    data: any = {},
    // eslint-disable-next-line 
    additionalHeaders: any | null = null
    // eslint-disable-next-line 
  ): Promise<any> {
    const headers = {
      headers: this.fillAuthHeaders(additionalHeaders ? additionalHeaders : {}),
    };

    // console.log("post", BASE_URL, url)

    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL + url, data, headers)
        .then((result) => {
          if (result.status < 200 || result.status >= 300) {
            reject(new Error(`${result.status} error`));
            return;
          }

          resolve(result);
          return;
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async requestResetPassword(username: string): Promise<void> {
    const data = {
      username: username
    };
    await axios.post(BASE_URL + URL_REQUEST_RESET_PASSWORD, data)
  }

  async resetPassword(company_id: number, username: string, new_password: string, token: string): Promise<void> {
    const data = {
      company_id: company_id
      , username: username
      , new_password: new_password
      , token: token
    };
    await axios.post(BASE_URL + URL_RESET_PASSWORD, data)
  }

  // **************** JWT ****************
  obtainToken(username: string, password: string) {
    const data = {
      username: username,
      password: password,
    };

    return new Promise((resolve, reject) => {
      console.log("obtainToken", BASE_URL, URL_OBTAIN_JWT);

      axios
        .post(BASE_URL + URL_OBTAIN_JWT, data)
        .then((response) => {
          this.setJWTAccess(response.data.access);
          this.setJWTRefresh(response.data.refresh);

          console.log(
            "obtain access",
            this.decodeToken(this.getJWTAccess() as string)
          );
          console.log(
            "obtain refresh",
            this.decodeToken(this.getJWTRefresh() as string)
          );

          resolve(response.data.token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  verifyToken() {
    return new Promise((resolve, reject) => {
      if (!this.getJWTAccess()) {
        reject();
      }

      const data = {
        token: this.getJWTAccess(),
      };
      this.post(URL_VERIFY_JWT, data)
        .then((response) => {
          //console.log("response", response)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // decodeToken(token: string): Token {
  //   return jwtDecode(token)
  // }

  isLogged(): boolean {
    if (this.getJWTAccess()) {
    // if (this.jwtState.jwtAccess) {
      return true;
    }

    return false;
    // if (this.state.jwtAccess) {
    //   const d: Date = this.decodeToken(this.state.jwtAccess)
    //   if (d.getTime() >= Date.now()) {
    //     return true
    //   }
    // }
    // if (this.state.jwtRefresh) {
    //   const d: Date = this.decodeToken(this.state.jwtRefresh)
    //   if (d.getTime() >= Date.now()) {
    //     return true
    //   }
    // }

    // return false
  }

  decodeToken(token: string | null): Token | null {
    if (! token) {
      return null
    }
    return jwtDecode(token);
  }

  // get customerId(): number | undefined {
  //   const token: Token | null = this.decodeToken(this.jwtState.jwtAccess);
  //   if (!token) {
  //     return undefined;
  //   }

  //   return (token as unknown as {customer_id: number | undefined}).customer_id;
  // }

  refreshToken() {
    return new Promise((resolve, reject) => {
      if (!this.getJWTAccess()) {
        reject();
        return;
      }

      const data = {
        refresh: this.getJWTRefresh(),
      };
      if (data.refresh) {
        console.log("refreshToken", this.decodeToken(data.refresh));
      }
      axios
        .post(BASE_URL + URL_REFRESH_JWT, data)
        .then((response) => {
          this.setJWTAccess(response.data.access);
          resolve(response.data.access);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

const instance = new Rest();

axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return new Promise((resolve, reject) => {
      const originalRequest = error.config;
      console.log(originalRequest._retry);
      if (error.response.status === 401 && !originalRequest._retry) {
        if (BASE_URL + URL_REFRESH_JWT == axios.getUri(originalRequest)) {
          // avoid infinite refresh when refresh_token has expired
          instance.disconnect();
          reject(error);
          return;
        }
        originalRequest._retry = true;
        instance
          .refreshToken()
          .then(() => {
            if (BASE_URL + URL_VERIFY_JWT == axios.getUri(originalRequest)) {
              const data = {
                token: instance.getJWTAccess(),
              };
              originalRequest.data = data;
            }
            instance.fillAuthHeaders(originalRequest.headers);

            axios
              .request(originalRequest)
              .then((result) => {
                resolve(result);
                return;
              })
              .catch((err) => {
                reject(err);
                return;
              });
          })
          .catch((err) => {
            reject(err);
            return;
          });
      } else {
        reject(error);
        return;
      }
    });
  }
);

export default instance;
