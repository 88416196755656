import { Customer } from "@/models/Customer";
import { CommonService } from "@/services/commonService";

const BASE_URL = "api/mobile/customers/";

class CustomersService extends CommonService {
  async getCustomer(id?: number): Promise<Customer> {
    if (!id) {
      throw new Error("ID required");
    }

    const result = await this.restService.get(`${BASE_URL}${id}/`);
    return Object.assign(Customer, result.data);
  }

  postCustomer(id: number, data: { plug_elec: number }): Promise<void> {
    return this.restService.post(`${BASE_URL}${id}/`, data);
  }

  stop(id: number): Promise<void> {
    return this.restService.post(`${BASE_URL}${id}/stop/`);
  }
}

export default new CustomersService();
