import { ICustomer, Customer } from "@/models/Customer";
import { User } from "@/models/User";
import customersService from "@/services/customersService";

export interface UsersStoreState {
  customer: Customer | null;
  user: User | null;
  lastStop: number;
}

class UsersStore {
  state: UsersStoreState = {
    customer: null,
    user: null,
    lastStop: 0,
  };

  async refresh(): Promise<void> {
    const result = await customersService.getCustomer(
      this.state.user?.customer_id
    );
    this.state.customer = Object.assign(new Customer(), result as ICustomer);
  }

  async stop(): Promise<void> {
    const customerId: number | undefined = this.state.user?.customer_id;

    if (!customerId) {
      throw new Error("ID required");
    }
    await customersService.stop(customerId);
    this.updateLastStop();
  }

  updateLastStop(): void {
    this.state.lastStop = Date.now();
  }
}

export default new UsersStore();
