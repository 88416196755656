import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";

const Hmi = () => import("@/views/Hmi.vue");
const Box = () => import("@/views/Box.vue");
const History = () => import("@/views/History.vue");
// const Alert = () => import("@/views/Alert.vue");
const Search = () => import("@/views/Search.vue");
const QRCode = () => import("@/views/QRCode.vue");
const Map = () => import("@/views/Map.vue");
const Login = () => import("@/views/Login.vue");
const ResetPassword = () => import("@/views/ResetPassword.vue");

import restService from "@/services/restService";
import { User } from "@/models/User";
import usersStore from "@/stores/usersStore";

Vue.use(VueRouter);

function refreshUser(): void {
  const user = restService.decodeToken(restService.jwtState.jwtAccess);
  console.log("user", user);
  if (user) {
    usersStore.state.user = Object.assign(new User(), user);
  }
}

function checkAuthent(to: Route, from: Route, next: NavigationGuardNext) {
  if (restService.isLogged()) {
    refreshUser();

    next();
    return;
  }
  console.log("not logged");
  next(`/login?path=${to.path}`);
}

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    component: Login,
  },
  {
    beforeEnter: checkAuthent,
    path: "/hmi",
    component: Hmi,
    children: [
      {
        path: "map",
        component: Map,
      },
      {
        path: "box/:id",
        component: Box,
      },
      {
        path: "history",
        component: History,
      },
      // {
      //   path: "alert",
      //   component: Alert,
      // },
      {
        path: "search",
        component: Search,
      },
      {
        path: "qrcode",
        component: QRCode,
      },
    ],
  },
  {
    path: "/resetpassword",
    component: ResetPassword,
  },
  {
    path: "/*",
    redirect: "/login",
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export function routeToLogin(username?: string): void {
  router.push({ path: "/login" + (username ? "?username=" + username : "")});
}

export function routeToHistory(): void {
  router.push("/hmi/history");
}

export function routeToMap(): void {
  router.push("/hmi/map");
}

export function routeToBox(id: number): void {
  router.push(`/hmi/box/${id}`);
}

export default router;
